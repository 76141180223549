import React from 'react'
import GCMSImg from 'graphcms-image'
import classNames from 'classnames'

import PlaceHolder from './placeholder.inline.svg'

import './styles.css'

const Image = ({ image, style, isAbsolute, contain }) => {
  const absoluteStyle = isAbsolute && {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }

  const styles = {
    ...absoluteStyle,
    ...style
  }

  if (!image) {
    return (
      <div className="bg-light-gray h-100 relative" style={styles}>
        <PlaceHolder className="w-20 light-silver absolute centered" />
      </div>
    )
  }

  return (
    <GCMSImg
      image={image}
      style={styles}
      className={classNames({
        'graphcms-image-wrapper--contain': contain
      })}
    />
  )
}

export default Image
