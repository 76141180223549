export const currencyFormatter = value => {
  return new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(value)
}

export const areaFormatter = value => {
  const number = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(value)

  return `${number} m²`
}

var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
export const dateFormatter = date =>
  new Date(date).toLocaleString('fi-FI', options)

export const handleYesNo = value => (value ? 'Kyllä' : 'Ei')

export const roomFormatter = value => {
  if (value === 'yksi_huone') {
    return '1h+k'
  }
  if (value === 'kaksi_huonetta') {
    return '2h+k'
  }
  if (value === 'kolme_huonetta') {
    return '3h+k'
  }
  if (value === 'nelja_huonetta') {
    return '4h+k'
  }
}
